import React from "react";
import { PageSettings } from "./config/page-settings.js";
import Content from "./components/content/content.jsx";
import Footer from "./components/footer/footer.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import {
  updateLoginUser,
  resetLogin,
  performLogout,
  checkIsLoggedIn,
} from "./redux/AuthAction.js";
import classNames from "classnames";
import { isObjectEmpty, stringIsNullOrEmpty } from "./util/Util.js";
import { withTranslation } from "react-i18next";
import ApiEngine from "./util/ApiEngine.js";
import { ApiKey, ApiUrl } from "./util/Constant";
import { themeGenerator } from "config/theme.js";
import { paramCase } from "change-case";

LoadingOverlay.propTypes = undefined;
class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleSetPageContent = (value) => {
      this.setState((state) => ({
        pageContent: value,
      }));
    };
    this.handleSetPageContentClass = (value) => {
      this.setState((state) => ({
        pageContentClass: value,
      }));
    };
    this.handleSetPageContentFullHeight = (value) => {
      this.setState((state) => ({
        pageContentFullHeight: value,
      }));
    };
    this.handleSetPageContentFullWidth = (value) => {
      this.setState((state) => ({
        pageContentFullWidth: value,
      }));
    };
    this.handleSetPageContentInverseMode = (value) => {
      this.setState((state) => ({
        pageContentInverseMode: value,
      }));
    };
    this.handleSetPageFooter = (value) => {
      this.setState((state) => ({
        pageFooter: value,
      }));
    };
    this.handleSetBodyWhiteBg = (value) => {
      if (value === true) {
        document.body.classList.add("bg-white");
      } else {
        document.body.classList.remove("bg-white");
      }
    };

    this.state = {
      hasError: false,
      template: "template_1",
      pageContent: true,
      pageContentClass: "",
      pageContentFullHeight: false,
      pageContentFullWidth: true,
      pageContentInverseMode: false,
      pageNavBar: "",
      handleSetPageContent: this.handleSetPageContent,
      handleSetPageContentClass: this.handleSetPageContentClass,
      handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
      handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
      handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

      pageFooter: false,
      handleSetPageFooter: this.handleSetPageFooter,
      error: null,
      errorInfo: null,

      handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

      interval1: null,
    };
  }

  static getDerivedStateFromError(error) {
      console.log(error);
    return { hasError: true };
  }



  /// <summary>
  /// Author: -
  /// </summary>
  componentDidMount() {
    //navigator.serviceWorker.getRegistrations().then(function (registrations) { for (let registration of registrations) { registration.unregister(); } });
    this.setState({
      hasGetSkinSettings: false,
      hasCustomSkin: false,
      activeTheme: 0,
      backgroundImage: "",
    });
    clearInterval(this.state.interval1);
    this.state.interval1 = null;
    this.props.checkIsLoggedIn();
    this.state.interval1 = setInterval(
      () => this.props.checkIsLoggedIn(),
      20000
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.languageState.languageId != prevProps.languageState.languageId
    ) {
      this.props.i18n.changeLanguage(this.props.languageState.languageId);
    }

    // if (this.state.interval1 == null) {
    // 	this.state.interval1 = setInterval(
    // 		() => this.props.checkIsLoggedIn(),
    // 		20000
    // 	);
    // }

    if (!this.state.hasCustomSkin) {
      let isLoggedIn = this.props.authState.isLoggedIn;
      if (isLoggedIn) {
        this.getSkinSettings();
      }
    }

    this.applyTheme();
  }

  // componentWillUnmount() {
  // 	clearInterval(this.state.interval1);
  // 	this.state.interval1 = null;
  // }

  applyTheme() {
    // console.log(this.state.template);
      document.documentElement.style.setProperty("--html-main-width", "500px");
      document.documentElement.style.setProperty("--html-min-width", "unset");

      if (this.props.appState.themeName) {
        const themeValue = themeGenerator(this.props.appState.themeName);

        for (const [key, value] of Object.entries(themeValue)) {
          const variableName = `--${paramCase(key)}`;
          document.documentElement.style.setProperty(variableName, value);
        }
      }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    //clearInterval(this.state.interval1);
  }

  async getSkinSettings() {
    if (window.location.pathname.match(/dashboard/)) {
      var responseJson = await ApiEngine.get(ApiUrl._API_GET_EXTRA_HTML_STYLE);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        this.state.hasCustomSkin = true;
        const style = document.createElement("style");
        style.textContent = '"' + responseJson[ApiKey._API_DATA_KEY] + '"';
        document.head.appendChild(style);
      }
      this.state.hasGetSkinSettings = true;
    } else {
      this.state.hasGetSkinSettings = false;
    }
  }

  render() {
    {
      /*return (
            <span style={{ color: "black" }}>App</span>
        )*/
    }
    if (this.state.errorInfo) {
      return (
        <div className="error" style={{ padding: "20px" }}>
          <img
            src={require("./assets/img/error.png")}
            style={{
              maxWidth: "100px",
              objectFit: "contain",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <h3>Something went wrong, please contact admin for help</h3>
          <div className="m-b-30">{window.navigator.userAgent}</div>
        </div>
      );
    } else {
      return (
        <PageSettings.Provider value={this.state} className="template-3">
          <LoadingOverlay
            active={
              this.props.appState.isBusy ||
              !this.props.authState.loginStateInitialized
            }
            styles={{
              overlay: (base) => ({
                ...base,
                zIndex: "10000",
                position: "fixed",
              }),
            }}
            className={classNames("loading-overlay")}
          >
            <img src={require("./assets/img/loading.gif")} />
            <p>{this.props.appState.message}</p>
          </LoadingOverlay>
          {!isObjectEmpty(this.props.appState.alert) && (
            <div className={`sweet-alert-container`}>
            {/* <div className={`sweet-alert-container` +  ` t3-sweet-alert-container`}> */}

              <SweetAlert
                {...this.props.appState.alert}
                style={
                  this.props.appState.member.id != undefined
                    ? {
                        backgroundColor: "#05102F",
                        borderColor: "white !important",
                        opacity: 0.9,
                        color: "white",
                      }
                    : ""
                }
              >
                {!stringIsNullOrEmpty(this.props.appState.alert.content) &&
                  (this.props.appState.alert.html
                    ? this.props.appState.alert.content
                    : this.props.appState.alert.content
                        .split("\n")
                        .map((item, key) => {
                          return (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          );
                        }))}
              </SweetAlert>
            </div>
          )}
          {this.props.authState.loginStateInitialized && (
            <>
              <div
                className="fade page-sidebar-fixed show page-container page-content-full-height"
                style={{
                  minHeight: "100vh",
                  height: "100%",
                  userSelect: "none",
                  WebkitTouchCallout: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                }}
              >
                <div
                  onClick={() => {
                    if (this.state.pageSidebarToggled) {
                      this.toggleMobileSidebar();
                    }
                  }}
                >
                  {this.state.pageContent && (
                    <Content template={this.state.template} />
                  )}
                </div>
              </div>
            </>
          )}
        </PageSettings.Provider>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  updateLoginUser: updateLoginUser,
  resetLogin: resetLogin,
  performLogout: performLogout,
  checkIsLoggedIn: checkIsLoggedIn,
})(withTranslation()(App));
