/// <summary>
/// Author: -
/// </summary>
import axios from "axios";
import { ApiKey } from "./Constant.js";
import * as AppActions from "../redux/AppAction";
import * as AuthActions from "../redux/AuthAction";
import store from "../ApplicationStore";
import i18n from "i18next";

const _HTTP_CODE_UNAUTHORIZED = 401;
const _HTTP_CODE_FORBIDDEN = 403;

/// <summary>
/// Author: -
/// </summary>
async function interceptRequest(requestConfig) {
  //store.dispatch(AppActions.setBusy());
  return requestConfig;
}

/// <summary>
/// Author: -
/// </summary>
function interceptResponse(response) {
  store.dispatch(
    AppActions.setPerformApiCall(
      response.data[ApiKey._API_SUCCESS_KEY],
      response.config.url
    )
  );

  //store.dispatch(AppActions.setIdle());
  return response.data;
}

/// <summary>
/// Author: -
/// </summary>
function interceptError(error) {
  var returnData = {};

  store.dispatch(AppActions.setIdle());
  if (error && error.response) {
    if (
      error.response.status == _HTTP_CODE_UNAUTHORIZED ||
      error.response.status == _HTTP_CODE_FORBIDDEN
    ) {
      store.dispatch(AuthActions.performLogout());
      // store.dispatch(
      //   AppActions.showResponseMessage(false, i18n.t("SESSION_EXPIRED"))
      // );
      returnData = {
        success: false,
        message: i18n.t("SESSION_EXPIRED"),
      };
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(error.response));
      }
    }
  }

  // if (!store.getState().authState.isLoggedIn) {
  //   store.dispatch(AuthActions.setToggleLoginModal(true));
  // }
  return returnData;
}

/*const baseURL = "https://staging.okup.app";*/
const baseURL = "";

const ApiEngine = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
  },
});

ApiEngine.interceptors.request.use(
  (config) => interceptRequest(config),
  (error) => interceptError(error)
);

ApiEngine.interceptors.response.use(
  (response) => interceptResponse(response),
  (error) => interceptError(error)
);

export default ApiEngine;
export { baseURL };
