import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  useHistory,
  Switch,
} from "react-router-dom";
import { fetchRoute, _LOGIN_ROUTE2 } from "../../config/page-route.jsx";
import { PageSettings } from "../../config/page-settings.js";
import { Status } from "../../util/Constant";
import { connect } from "react-redux";
import { setBottomTabIndex, setBusy, setRegisterModal } from "../../redux/AppAction";
import Footer from "components/footer/footer.jsx";
import LoginModal from "components/modal/login-modal.js";
import { setToggleLoginModal } from "redux/AuthAction.js";
import reactManifest from "react-manifest"
import manifestRC from "../../manifest-rc.json"
import manifest from "../../manifest.json"
import { Placeholder } from "react-bootstrap";
import { find } from "lodash";

function setTitle(path, routeArray, template) {
  var pageTitle;
 //dynamic favicon and title
  if (template == "template_1") {
    for (var i = 0; i < routeArray.length; i++) {
      if (routeArray[i].path === path) {
        pageTitle = "OKUP | " + routeArray[i].title;
      }
    }

    document.title = "OKUP";
    reactManifest.update(manifest, "#manifest-placeholder")
  }
  else {
    document.title = "OKUP";
    for (var i = 0; i < routeArray.length; i++) {
      if (routeArray[i].path === path) {
        pageTitle = "OKUP | " + routeArray[i].title;
      }
    }
    reactManifest.update(manifest, "#manifest-placeholder")
  }

  //document.title = pageTitle ? pageTitle : "OKUP";

}

class Content extends React.Component {
  constructor(props) {
    super(props);
    this._TIMEOUT_TIME = 60 * 60 * 1000;
    this.state = {
      template: props.template ?? "template_1",
      routes: null,
      currroutes: null,
      loginRoute: null,
      currentPage: this.props.history.location.pathname,
      bottomNavIndex: 0,
    };

  }

  ComponentRoute(routeComponent){

    return routeComponent
  }
  

  /// <summary>
  /// Author :
  /// </summary>

  PrivateRoute(index, routeData) {

    return (
      <Route
        key={index}
        exact={
          routeData.authRequired && !this.props.authState.isLoggedIn
            ? this.state.loginRoute.exact
            : routeData.exact
        }
        path={
          routeData.authRequired && !this.props.authState.isLoggedIn
            ? this.state.loginRoute.path
            : routeData.path
        }
        render={() => (
          <React.Suspense fallback={null}>
            {/* {routeData.authRequired && !this.props.authState.isLoggedIn
              ? this.state.loginRoute.component
              : routeData.component} */}
                  {routeData.authRequired && !this.props.authState.isLoggedIn
              ? this.state.loginRoute.component
              : this.ComponentRoute(routeData.component)}
          </React.Suspense>
        )}
      />
    );
  }
  componentWillUpdate() {
    if (this.currentPage !== this.props.history.location.pathname) {
      this.currentPage = this.props.history.location.pathname;
      this.resetTimeout();
    }

     
    


  }

  async loadData() {

    try {
      const dashboardRouteVar = await fetchRoute(this.state.template, "dashboard");
      const loginRouteVar = await fetchRoute(this.state.template, "login");
      const routesVar = await fetchRoute(this.state.template, "all");

      //if loggedin is false trigger modal
      if (
        this.state.currentPage !== "/welcome" &&
        this.state.currentPage !== "/" &&
        !this.props.authState.isLoggedIn
      ) {
        this.props.dispatch(setToggleLoginModal(true));
      }

      let pageExisted  = find(routesVar, {path:this.state.currentPage})
      if(!pageExisted){
        window.location.replace('/')     
      }
      
      this.setState({
        routes: routesVar,
        loginRoute: loginRouteVar,
        currroutes: null,
      });

      // Now you can work with the data
    } catch (error) {
      // Handle errors
    }
  }

  componentDidMount() {
    this.loadData();


    if (this.props.history.location.pathname !== "/welcome") {
    this.startTimeout = setTimeout(() => {
        window.location.reload();
    }, this._TIMEOUT_TIME);
    }

    setTitle(this.props.history.location.pathname, Route, this.state.template);
  }

  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, Route, this.state.template);
    });

 
  }

  /// <summary>
  /// Author : -
  /// Edit: added localstorage
  /// </summary>
  resetTimeout = () => {
    clearTimeout(this.startTimeout);
    if (
    this.currentPage !== "/welcome" &&
    this.props.history.location.pathname !== "/welcome"
    ) {
    this.startTimeout = setTimeout(() => {
        window.location.reload();
    }, this._TIMEOUT_TIME);
    }
  };

  render() {
    if (this.state.routes == null) return false;
    this.state.currroutes = this.state.routes.filter((route) => {
      return route.path == this.props.history.location.pathname;
    });

    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div>
            <div
              className={
                "content " +
                (pageContentFullWidth ? "content-full-width " : "") +
                (pageContentInverseMode ? "content-inverse-mode " : "") +
                pageContentClass 
              }
            >
              {this.state.currroutes.map((route, index) => {
                return this.PrivateRoute(index, route);
              })}
            </div>
            <Footer props={this.props}/>
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, null)(withRouter(Content));
