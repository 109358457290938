import React, { lazy, Suspense } from "react";
import { Redirect } from "react-router";
import { WebUrl } from "../util/Constant";
import RecentBigWinInfo from "pages/template_1/home/RecentBigWin";

export const _LOGIN_ROUTE2 = {
  path: WebUrl._URL_MAIN,
  exact: false,
  authRequired: false,
  component: <Redirect to={WebUrl._URL_WELCOME} />,
};

export async function fetchRoute(themeVar, type) {
  // Use the variable to fetch data or perform some logic
  // (`Fetching data for variable: ${themeVar}`);

  const Welcome = lazy(async () => await import(`pages/${themeVar}/Welcome`));
  const Login = lazy(async () => await import(`pages/${themeVar}/Login`));
  const LiveChat = lazy(async () => await import(`pages/${themeVar}/LiveChat`));
  const RegisterCode = lazy(
    async () => await import(`pages/${themeVar}/RegisterCode`)
  );
  const CreateAnAccount = lazy(
    async () => await import(`pages/${themeVar}/CreateAnAccount`)
  );
  const Register = lazy(async () => await import(`pages/${themeVar}/Register`));
  const Forgot = lazy(async () => await import(`pages/${themeVar}/Forgot`));
  const Dashboard = lazy(
    async () => await import(`pages/${themeVar}/home/Dashboard`)
  );
  const ReferralGroup2 = lazy(
    async () => await import(`pages/${themeVar}/referral/ReferralGroup2`)
  );
  const ReferralGroupDetail3 = lazy(
    async () => await import(`pages/${themeVar}/referral/ReferralGroupDetail3`)
  );

  const Transaction = lazy(
    async () => await import(`pages/${themeVar}/transaction/Transaction`)
  );
  const TransferSuccessful = lazy(
    async () => await import(`pages/${themeVar}/transaction/TransferSuccessful`)
  );
  const Withdrawal = lazy(
    async () => await import(`pages/${themeVar}/transaction/WithdrawalMobile`)
  );
  const WithdrawalRecord = lazy(
    async () => await import(`pages/${themeVar}/transaction/WithdrawalRecord`)
  );
  const Deposit = lazy(
    async () => await import(`pages/${themeVar}/transaction/Deposit`)
  );
  const DepositRecord = lazy(
    async () => await import(`pages/${themeVar}/transaction/DepositRecord`)
  );
  const Downline = lazy(
    async () => await import(`pages/${themeVar}/home/Downline`)
  );
  const MyDownline = lazy(
    async () => await import(`pages/${themeVar}/home/MyDownline`)
  );
  const DownlineDetails = lazy(
    async () => await import(`pages/${themeVar}/home/DownlineDetails`)
  );
  const Settings = lazy(() => import(`pages/${themeVar}/home/Settings`));
  const SalesCommission = lazy(async () => await import(`pages/${themeVar}/home/SalesCommission`));
  const News = lazy(async () => await import(`pages/${themeVar}/home/News`));
  const VideoTutorial = lazy(
    async () => await import(`pages/${themeVar}/home/VideoTutorial`)
  );
  const NewsDetails = lazy(
    async () => await import(`pages/${themeVar}/home/NewsDetails`)
  );
  const GameLobby = lazy(
    async () => await import(`pages/${themeVar}/game/GameLobby`)
  );
  const UnderMaintenance = lazy(
    async () => await import(`pages/${themeVar}/game/UnderMaintenance`)
  );
  const ReportMenu = lazy(
    async () => await import(`pages/${themeVar}/report/ReportMenu`)
  );
  const WalletHistory = lazy(
    async () => await import(`pages/${themeVar}/report/WalletHistory`)
  );
  const WalletHistoryMobile = lazy(
    async () => await import(`pages/${themeVar}/report/WalletHistoryMobile`)
  );
  const CommissionHistory = lazy(
    async () => await import(`pages/${themeVar}/report/CommissionHistory`)
  );
  const BonusHistory = lazy(
    async () => await import(`pages/${themeVar}/report/BonusHistory`)
  );
  const InternaTransferHistory = lazy(
    async () => await import(`pages/${themeVar}/report/InternaTransferHistory`)
  );
  const RolloverStatement = lazy(
    async () => await import(`pages/${themeVar}/report/RolloverStatement`)
  );
  const TransactionHistory = lazy(
    async () => await import(`pages/${themeVar}/report/TransactionHistory`)
  );
  const GameHistory = lazy(
    async () => await import(`pages/${themeVar}/report/GameHistory`)
  );
  const GameDetails = lazy(
    async () => await import(`pages/${themeVar}/report/GameDetails`)
  );
  const MembersTransfer = lazy(
    async () => await import(`pages/${themeVar}/report/MembersTransfer`)
  );
  const GameWalletTransfer = lazy(
    async () => await import(`pages/${themeVar}/report/GameWalletTransfer`)
  );
  const ReportsOverview = lazy(
    async () => await import(`pages/${themeVar}/report/ReportsOverview`)
  );
  const ForgetPin = lazy(
    async () => await import(`pages/${themeVar}/member/ForgetPin`)
  );
  const DownlineGroupDetail = lazy(
    async () => await import(`pages/${themeVar}/member/DownlineGroupDetail`)
  );
  const UnclaimCommissionHistory = lazy(
    async () =>
      await import(`pages/${themeVar}/member/UnclaimCommissionHistory`)
  );
  const Maintenance = lazy(
    async () => await import(`pages/${themeVar}/Maintenance`)
  );
  const ContactUs = lazy(
    async () => await import(`pages/${themeVar}/home/ContactForm`)
  );
  const LiveChatPage = lazy(
    async () => await import(`pages/${themeVar}/home/LiveChat`)
  );
  const Shareholder = lazy(
    async () => await import(`pages/${themeVar}/shareholder/Shareholder`)
  );
  const ShareholderDetails = lazy(
    async () => await import(`pages/${themeVar}/shareholder/ShareholderDetails`)
  );
  const EditShareholder = lazy(
    async () => await import(`pages/${themeVar}/shareholder/EditShareholder`)
  );
  const RewardsTnC = lazy(
    async () => await import(`pages/${themeVar}/home/RewardsTnC`)
  );
  const ClaimRecord = lazy(
    async () => await import(`pages/${themeVar}/shareholder/ClaimRecord`)
  );
  const SummaryDownlineSales = lazy(
    async () =>
      await import(`pages/${themeVar}/shareholder/SummaryDownlineSales`)
  );

  const Rewards = lazy(
    async () => await import(`pages/${themeVar}/home/Rewards`)
  );
  const RewardsHistory = lazy(
    async () => await import(`pages/${themeVar}/report/RewardsHistory`)
  );
  const RewardsDetail = lazy(
    async () => await import(`pages/${themeVar}/report/RewardsDetail`)
  );
  const GamePage = lazy(
    async () => await import(`pages/${themeVar}/home/GamePage`)
  );
  const WalletTransferHistory = lazy(
    async () => await import(`pages/${themeVar}/report/WalletTransferHistory`)
  );
  const WalletStatement = lazy(
    async () => await import(`pages/${themeVar}/report/WalletStatement`)
  );
  const TouchnGO = lazy(
    async () => await import(`pages/${themeVar}/transaction/ReloadTnG`)
  );
  const ReloadBank = lazy(
    async () => await import(`pages/${themeVar}/transaction/ReloadBank`)
  );
  const UploadReloadReceipt = lazy(
    async () =>
      await import(`pages/${themeVar}/transaction/UploadReloadReceipt`)
  );
  const ReloadDone = lazy(
    async () => await import(`pages/${themeVar}/transaction/ReloadDone`)
  );
  const MainWallet = lazy(
    async () => await import(`pages/${themeVar}/transaction/MainWallet`)
  );
  const GameReload = lazy(
    async () => await import(`pages/${themeVar}/game/GameReload`)
  );
  const WithdrawalMethods = lazy(
    async () => await import(`pages/${themeVar}/transaction/WithdrawalMethods`)
  );
  const WithdrawByBankTransfer = lazy(
    async () =>
      await import(`pages/${themeVar}/transaction/WithdrawByBankTransfer`)
  );
  const ScanQR = lazy(async () => await import(`pages/${themeVar}/qr/ScanQR`));
  const CreateAccount = lazy(
    async () => await import(`pages/${themeVar}/qr/CreateAccount`)
  );
  const VerifyCreateAccount = lazy(
    async () => await import(`pages/${themeVar}/qr/Verify`)
  );
  const CreatePassword = lazy(
    async () => await import(`pages/${themeVar}/qr/CreatePassword`)
  );
  const CreateAccountSuccess = lazy(
    async () => await import(`pages/${themeVar}/qr/Success`)
  );
  const CreateAccountByReferralCode = lazy(
    async () => await import(`pages/${themeVar}/qr/Referral`)
  );
  const WithdrawalInProgress = lazy(
    async () =>
      await import(`pages/${themeVar}/transaction/WithdrawalInProgress`)
  );
  const Commission = lazy(
    async () => await import(`pages/${themeVar}/home/Commission`)
  );
  const Transfer = lazy(
    async () => await import(`pages/${themeVar}/transaction/Transfer`)
  );
  const VipBenefits = lazy(
    async () => await import(`pages/${themeVar}/home/VipBenefits`)
  );
  const Promotion = lazy(
    async () => await import(`pages/${themeVar}/home/Promotion`)
  );
  const PromotionInfo = lazy(
    async () => await import(`pages/${themeVar}/home/PromotionInfo`)
  );
  const Giftpace = lazy(
    async () => await import(`pages/${themeVar}/transaction/Giftpace`)
  );
  const CommissionsInfo = lazy(
    async () => await import(`pages/${themeVar}/home/CommissionsInfo`)
  );
  const Topkash = lazy(
    async () => await import(`pages/${themeVar}/transaction/Topkash`)
  );
  const WithdrawByTopkash = lazy(
    async () => await import(`pages/${themeVar}/transaction/WithdrawByTopkash`)
  );
  const DailyCheckIn = lazy(
    async () => await import(`pages/${themeVar}/home/DailyCheckIn`)
  );
  const DCRedemption = lazy(
    async () => await import(`pages/${themeVar}/home/DCRedemption`)
  );
  const DCRedemptionHistory = lazy(
    async () => await import(`pages/${themeVar}/report/DCRedemptionHistory`)
  );
  const UnclaimDCPoints = lazy(
    async () => await import(`pages/${themeVar}/member/UnclaimDCPoints`)
  );
  const DCPointsHistory = lazy(
    async () => await import(`pages/${themeVar}/report/DCPointsHistory`)
  );
  const VipCommissionHistory = lazy(
    async () => await import(`pages/${themeVar}/report/VipCommissionHistory`)
  );

  const HuiOneDeposit = lazy(
    async () => await import(`pages/${themeVar}/transaction/HuiOneDeposit`)
  );
  const WithdrawByHuiOne = lazy(
    async () => await import(`pages/${themeVar}/transaction/WithdrawByHuiOne`)
  );
  const CryptoWalletsDeposit = lazy(
    async () =>
      await import(`pages/${themeVar}/transaction/CryptoWalletsDeposit`)
  );

  const CryptoDeposit = lazy(
    async () => await import(`pages/${themeVar}/transaction/CryptoDeposit`)
  );

  const FSPromotion = lazy(
    async () => await import(`pages/${themeVar}/home/FSPromotion`)
  );

  ///template 3
  const WingWeiluyDeposit = lazy(
    async () => await import(`pages/${themeVar}/transaction/WingWeiluyDeposit`)
  );
  const UploadWingWeiluyReceipt = lazy(
    async () => await import(`pages/${themeVar}/transaction/UploadWingWeiluyReceipt`)
  );
  const WithdrawByWingWeiluy = lazy(
    async () => await import(`pages/${themeVar}/transaction/withdrawByWingWeiluy`)
  );
  const WithdrawByBCPay = lazy(
    async () => await import(`pages/${themeVar}/transaction/WithdrawByBCPay`)
  );

  const Kpi = lazy(async () => await import(`pages/${themeVar}/home/Kpi`));



  // Add your data fetching logic here

  const _LOGIN_ROUTE = {
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: <Redirect to={WebUrl._URL_DASHBOARD} />,
  };
  const _DASHBOARD_ROUTE = {
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: <Redirect to={WebUrl._URL_DASHBOARD} />,
  };

  const _LOGIN_ROUTE3 = {
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: <Redirect to={WebUrl._URL_WELCOME} />,
  };

  const _ROUTES = [
    {
      path: WebUrl._URL_WELCOME,
      exact: true,
      title: "Welcome",
      authRequired: false,
      component: <Welcome />,
    },
    {
      path: WebUrl._URL_LOGIN,
      exact: true,
      title: "Login",
      authRequired: false,
      component: <Login />,
    },
    {
      path: WebUrl._URL_LIVECHAT,
      exact: true,
      title: "LiveChat",
      authRequired: false,
      component: <LiveChat />,
    },
    {
      path: WebUrl._URL_MLMREGISTER,
      exact: true,
      title: "Register",
      authRequired: false,
      component: <RegisterCode />,
    },
    {
      path: WebUrl._URL_MLMCREATEANACCOUNT,
      exact: true,
      title: "Create An Account",
      authRequired: false,
      component: <CreateAnAccount />,
    },
    {
      path: WebUrl._URL_FORGOT,
      exact: true,
      title: "Forgot",
      authRequired: false,
      component: <Forgot />,
    },
    {
      path: WebUrl._URL_UNDER_MAINTENANCE,
      exact: true,
      title: "Under Maintenance",
      authRequired: false,
      component: <UnderMaintenance />,
    },
    {
      path: WebUrl._URL_MAIN,
      exact: true,
      authRequired: true,
      component: <Redirect to={WebUrl._URL_DASHBOARD} />,
    },
    {
      path: WebUrl._URL_DASHBOARD,
      exact: true,
      title: "Dashboard",
      authRequired: false,
      component: <Dashboard />,
    },
    {
      path: WebUrl._URL_SETTINGS,
      exact: true,
      title: "Settings",
      authRequired: false,
      component: <Settings />,
    },
    {
      path: WebUrl._URL_REGISTER,
      exact: true,
      title: "Register",
      authRequired: false,
      component: <Register />,
    },
    {
      path: WebUrl._URL_KEY_IN_REFERRAL,
      exact: true,
      title: "Key In Referral",
      authRequired: false,
      component: <Register />,
    },
    {
      path: WebUrl._URL_DOWNLINE,
      exact: true,
      title: "Downline",
      authRequired: true,
      component: <Downline />,
    },
    {
      path: WebUrl._URL_MY_DOWNLINES,
      exact: true,
      title: "My Downline",
      authRequired: true,
      component: <MyDownline />,
    },
    {
      path: WebUrl._URL_DOWNLINE_DETAILS,
      exact: true,
      title: "Downline Details",
      authRequired: true,
      component: <DownlineDetails />,
    },
    {
      path: WebUrl._URL_REPORTS,
      exact: true,
      title: "Activity",
      authRequired: true,
      component: <ReportMenu />,
    },
    {
      path: WebUrl._URL_TRANSACTION,
      exact: true,
      title: "Transaction",
      authRequired: true,
      component: <Transaction reload={false} />,
    },
    {
      path: WebUrl._URL_TRANSFER_SUCCESS,
      exact: true,
      title: "Transfer Success",
      authRequired: true,
      component: <TransferSuccessful reload={false} />,
    },
    {
      path: WebUrl._URL_RELOAD_TRANSACTION,
      exact: true,
      title: "Deposit",
      authRequired: true,
      component: <Transaction reload={true} />,
    },
    {
      path: WebUrl._URL_WITHDRAWAL_RECORD,
      exact: true,
      title: "Withdrawal Record",
      authRequired: true,
      component: <WithdrawalRecord />,
    },
    {
      path: WebUrl._URL_DEPOSIT_RECORD,
      exact: true,
      title: "Deposit Record",
      authRequired: true,
      component: <DepositRecord />,
    },
    {
      path: WebUrl._URL_WALLET_HISTORY,
      exact: true,
      title: "Wallet History",
      authRequired: true,
      component: <WalletHistory />,
    },
    {
      path: WebUrl._URL_WALLET_HISTORY_MOBILE,
      exact: true,
      title: "Wallet History",
      authRequired: true,
      component: <WalletHistoryMobile />,
    },
    {
      path: WebUrl._URL_TRANSACTION_HISTORY,
      exact: true,
      title: "Transaction History",
      authRequired: true,
      component: <TransactionHistory />,
    },
    {
      path: WebUrl._URL_BONUS_HISTORY,
      exact: true,
      title: "Bonus History",
      authRequired: true,
      component: <BonusHistory />,
    },
    {
      path: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
      exact: true,
      title: "Internal Transfer History",
      authRequired: true,
      component: <InternaTransferHistory />,
    },
    {
      path: WebUrl._URL_WALLET_TRANSFER_HISTORY,
      exact: true,
      title: "Wallet Transfer History",
      authRequired: true,
      component: <WalletTransferHistory />,
    },
    {
      path: WebUrl._URL_COMMISSION_HISTORY,
      exact: true,
      title: "Commission History",
      authRequired: true,
      component: <CommissionHistory />,
    },
    {
      path: WebUrl._URL_ROLLOVER_STATEMENT,
      exact: true,
      title: "Rollover Statement",
      authRequired: true,
      component: <RolloverStatement />,
    },
    {
      path: WebUrl._URL_GAME_HISTORY,
      exact: true,
      title: "Game History",
      authRequired: true,
      component: <GameHistory />,
    },
    {
      path: WebUrl._URL_MEMBERS_TRANSFER,
      exact: true,
      title: "Transfers From / To Members",
      authRequired: true,
      component: <MembersTransfer />,
    },
    {
      path: WebUrl._URL_GAME_WALLET_TRANSFER,
      exact: true,
      title: "Transfers(Wallets)",
      authRequired: true,
      component: <GameWalletTransfer />,
    },
    {
      path: WebUrl._URL_REPORTS_OVERVIEW,
      exact: true,
      title: "Activity",
      authRequired: true,
      component: <ReportsOverview />,
    },
    {
      path: WebUrl._URL_FORGET_PIN,
      exact: true,
      title: "Forget PIN",
      authRequired: true,
      component: <ForgetPin />,
    },
    {
      path: WebUrl._URL_GAME_LOBBY,
      exact: true,
      title: "Game Lobby",
      authRequired: false,
      component: <GameLobby />,
    },
    {
      path: WebUrl._URL_SALES_COMMISSION,
      exact: true,
      title: "SALES",
      authRequired: true,
      component: <SalesCommission />,
    },
    {
      path: WebUrl._URL_SHAREHOLDER,
      exact: true,
      title: "Shareholder",
      authRequired: true,
      component: <Shareholder />,
    },
    {
      path: WebUrl._URL_SHAREHOLDER_DETAIL,
      exact: true,
      title: "Shareholder Detail",
      authRequired: true,
      component: <ShareholderDetails />,
    },
    {
      path: WebUrl._URL_EDIT_SHAREHOLDER,
      exact: true,
      title: "Edit Shareholder",
      authRequired: true,
      component: <EditShareholder />,
    },
    {
      path: WebUrl._URL_NEWS,
      exact: true,
      title: "News",
      authRequired: true,
      component: <News />,
    },
    {
      path: WebUrl._URL_NEWS_DETAILS,
      exact: true,
      title: "Detail",
      authRequired: true,
      component: <NewsDetails />,
    },
    {
      path: WebUrl._URL_MAINTENANCE,
      exact: true,
      title: "Maintenance",
      component: <Maintenance />,
    },
    {
      path: WebUrl._URL_GAME_DETAILS,
      exact: true,
      title: "Game Detail",
      authRequired: true,
      component: <GameDetails />,
    },
    {
      path: WebUrl._URL_CONTACT_US,
      exact: true,
      title: "Contact Us",
      component: <ContactUs />,
    },
    {
      path: WebUrl._URL_LIVE_CHAT,
      exact: true,
      title: "Live Chat",
      component: <LiveChatPage />,
    },
    {
      path: WebUrl._URL_CLAIM_RECORD,
      exact: true,
      title: "Claim History",
      component: <ClaimRecord />,
    },
    {
      path: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
      exact: true,
      title: "Group Details",
      component: <DownlineGroupDetail />,
    },
    {
      path: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY,
      exact: true,
      title: "Unclaim Commission History",
      authRequired: true,
      component: <UnclaimCommissionHistory />,
    },
    {
      path: WebUrl._URL_VIDEO_TUTORIAL,
      exact: true,
      title: "Video Tutorial",
      authRequired: true,
      component: <VideoTutorial />,
    },
    {
      path: WebUrl._URL_WITHDRAWAL,
      exact: true,
      title: "Withdrawal",
      authRequired: true,
      component: <Withdrawal />,
    },
    {
      path: WebUrl._URL_DEPOSIT,
      exact: true,
      title: "Deposit",
      authRequired: true,
      component: <Deposit />,
    },
    {
      path: WebUrl._URL_SUMMARY_DOWNLINE_SALES,
      exact: true,
      title: "Summary Downline",
      authRequired: true,
      component: <SummaryDownlineSales />,
    },
    {
      path: WebUrl._URL_REWARDS,
      exact: true,
      title: "Rewards",
      authRequired: true,
      component: <Rewards />,
    },
    {
      path: WebUrl._URL_REWARDS_HISTORY,
      exact: true,
      title: "Rewards History",
      authRequired: true,
      component: <RewardsHistory />,
    },
    {
      path: WebUrl._URL_REWARDS_DETAIL,
      exact: true,
      title: "Rewards Detail",
      authRequired: true,
      component: <RewardsDetail />,
    },
    {
      path: WebUrl._URL_GAME_PAGE,
      exact: true,
      title: "Game",
      authRequired: true,
      component: <GamePage />,
    },
    {
      path: WebUrl._URL_REWARDS_TNC,
      exact: true,
      title: "Rewards Terms & Conditions",
      authRequired: true,
      component: <RewardsTnC />,
    },
    {
      path: WebUrl._URL_MAIN_WALLET,
      exact: true,
      title: "Main Wallet",
      authRequired: true,
      component: <MainWallet />,
    },
    {
      path: WebUrl._URL_RELOAD_BANK,
      exact: true,
      title: "Deposit Bank",
      authRequired: true,
      component: <ReloadBank />,
    },
    {
      path: WebUrl._URL_RELOAD_TOUCH_N_GO,
      exact: true,
      title: "Deposit Touch & Go",
      authRequired: true,
      component: <TouchnGO />,
    },
    {
      path: WebUrl._URL_UPLOAD_RELOAD_RECEIPT,
      exact: true,
      title: "Upload Deposit Receipt",
      authRequired: true,
      component: <UploadReloadReceipt />,
    },
    {
      path: WebUrl._URL_RELOAD_DONE,
      exact: true,
      title: "Deposit Done",
      authRequired: true,
      component: <ReloadDone />,
    },
    {
      path: WebUrl._URL_GAME_RELOAD,
      exact: true,
      title: "Game Deposit",
      authRequired: true,
      component: <GameReload />,
    },
    {
      path: WebUrl._URL_WALLET_STATEMENT,
      exact: true,
      title: "Wallet Statement",
      authRequired: true,
      component: <WalletStatement />,
    },
    {
      path: WebUrl._URL_WITHDRWAL_METHODS,
      exact: true,
      title: "Withdrawal",
      authRequired: true,
      component: <WithdrawalMethods />,
    },
    {
      path: WebUrl._URL_WITHDRWAL_BY_BANK_TRANSFER,
      exact: true,
      title: "Withdraw By Bank Transfer",
      authRequired: true,
      component: <WithdrawByBankTransfer />,
    },
    {
      path: WebUrl._URL_SCAN_QR,
      exact: true,
      title: "Scan QR",
      authRequired: false,
      component: <ScanQR />,
    },
    {
      path: WebUrl._URL_CREATE_ACCOUNT,
      exact: true,
      title: "Create Account",
      authRequired: false,
      component: <CreateAccount />,
    },
    {
      path: WebUrl._URL_VERIFY_CREATE_ACCOUNT,
      exact: true,
      title: "Create Account",
      authRequired: false,
      component: <VerifyCreateAccount />,
    },
    {
      path: WebUrl._URL_CREATE_PASSWORD,
      exact: true,
      title: "Create Password",
      authRequired: false,
      component: <CreatePassword />,
    },
    {
      path: WebUrl._URL_CREATE_ACCOUNT_SUCCESS,
      exact: true,
      title: "Create Account Success",
      authRequired: false,
      component: <CreateAccountSuccess />,
    },
    {
      path: WebUrl._URL_CREATE_ACCOUNT_BY_REFERRAL_CODE,
      exact: true,
      title: "Create Account By Referral Code",
      authRequired: false,
      component: <CreateAccountByReferralCode />,
    },
    {
      path: WebUrl._URL_WITHDRAWAL_IN_PROGRESS,
      exact: true,
      title: "Withdrawal In Progress",
      authRequired: true,
      component: <WithdrawalInProgress />,
    },
    {
      path: WebUrl._URL_COMMISSION,
      exact: true,
      title: "Commission",
      authRequired: true,
      component: <Commission />,
    },
    {
      path: WebUrl._URL_TRANSFER,
      exact: true,
      title: "Transfer",
      authRequired: true,
      component: <Transfer />,
    },
    {
      path: WebUrl._URL_VIP_BENEFITS,
      exact: true,
      title: "Vip Benefits",
      authRequired: false,
      component: <VipBenefits />,
    },
    {
      path: WebUrl._URL_PROMOTION,
      exact: true,
      title: "Promotion",
      authRequired: false,
      component: <Promotion />,
    },
    {
      path: WebUrl._URL_PROMOTION_INFO,
      exact: true,
      title: "Promotion Information",
      authRequired: false,
      component: <PromotionInfo />,
    },
    {
      path: WebUrl._URL_GIFTPACE,
      exact: true,
      title: "Giftpace",
      authRequired: true,
      component: <Giftpace />,
    },
    {
      path: WebUrl._URL_REFERRAL_GROUP_DETAIL,
      exact: true,
      title: "Referral Group Detail",
      authRequired: true,
      component: <ReferralGroupDetail3 />,
    },
    {
      path: WebUrl._URL_REFERRAL_GROUPS,
      exact: true,
      title: "Referral Group",
      authRequired: true,
      component: <ReferralGroup2 />,
    },
    {
      path: WebUrl._URL_COMMISSIONS_INFO,
      exact: true,
      title: "Commissions Information",
      authRequired: false,
      component: <CommissionsInfo />,
    },
    {
      path: WebUrl._URL_TOPKASH,
      exact: true,
      title: "Deposit Topkash",
      authRequired: true,
      component: <Topkash />,
    },
    {
      path: WebUrl._URL_WITHDRAWAL_BY_TOPKASH,
      exact: true,
      title: "Withdraw by Topkash",
      authRequired: true,
      component: <WithdrawByTopkash />,
    },
    {
      path: WebUrl._URL_DAILY_CHECK_IN,
      exact: true,
      title: "Daily Check In",
      authRequired: true,
      component: <DailyCheckIn />,
    },
    {
      path: WebUrl._URL_DC_REDEMPTION,
      exact: true,
      title: "DC Redemption",
      authRequired: true,
      component: <DCRedemption />,
    },
    {
      path: WebUrl._URL_DC_REDEMPTION_HISTORY,
      exact: true,
      title: "DC Redemption History",
      authRequired: true,
      component: <DCRedemptionHistory />,
    },
    {
      path: WebUrl._URL_UNCLAIM_DC_POINTS,
      exact: true,
      title: "Unclaim DC Points",
      authRequired: true,
      component: <UnclaimDCPoints />,
    },
    {
      path: WebUrl._URL_DC_POINTS_HISTORY,
      exact: true,
      title: "DC Points History",
      authRequired: true,
      component: <DCPointsHistory />,
    },
    {
      path: WebUrl._URL_VIP_COMMISSION_HISTORY,
      exact: true,
      title: "Vip Commission History",
      authRequired: true,
      component: <VipCommissionHistory />,
    },
    {
      path: WebUrl._URL_HUIONE_DEPOSIT,
      exact: true,
      title: "Deposit HuiOne",
      authRequired: true,
      component: <HuiOneDeposit />,
    },
    {
      path: WebUrl._URL_WITHDRAWAL_BY_HUIONE,
      exact: true,
      title: "Withdraw by HuiOne",
      authRequired: true,
      component: <WithdrawByHuiOne />,
    },
    {
      path: WebUrl._URL_CRYPTO_WALLETS,
      exact: true,
      title: "Deposit Crypto Wallets",
      authRequired: true,
      component: <CryptoWalletsDeposit />,
    },
    {
      path: WebUrl._URL_CRYPTO_DEPOSIT,
      exact: true,
      title: "Deposit Crypto",
      authRequired: true,
      component: <CryptoDeposit />,
    },
    {
      path: WebUrl._URL_WING_WEILUY_DEPOSIT,
      exact: true,
      title: "Wing Weiluy Deposit",
      authRequired: true,
      component: <WingWeiluyDeposit />,
    },
    {
      path: WebUrl._URL_UPLOAD_WING_WEILUY_RECEIPT,
      exact: true,
      title: "Upload Wing Weiluy Receip",
      authRequired: true,
      component: <UploadWingWeiluyReceipt />,
    },
    {
      path: WebUrl._URL_WITHDRAW_BY_WING_WEILUY,
      exact: true,
      title: "Withdraw by Wing Weiluy",
      authRequired: true,
      component: <WithdrawByWingWeiluy />,
    },
    {
      path: WebUrl._URL_WITHDRAWAL_BY_BCPAY,
      exact: true,
      title: "Withdraw by BCPay",
      authRequired: true,
      component: <WithdrawByBCPay />,
    },
    {
      path: WebUrl._URL_FS_PROMOTION,
      exact: true,
      title: "FS Promotion",
      authRequired: false,
      component: <FSPromotion />,
    },
    {
      path: WebUrl._URL_RECENT_BIG_WIN,
      exact: true,
      title: "Recent Big Win",
      authRequired: false,
      component: <RecentBigWinInfo />,
    },
    {
      path: WebUrl._URL_KPI,
      exact: true,
      title: "SALES",
      authRequired: true,
      component: <Kpi />,
    },

  ];
  if (type == "dashboard") { 
    return _DASHBOARD_ROUTE;
  }else if (type == "login"){
    return _LOGIN_ROUTE;
  }else {
    return _ROUTES;
  }
}
