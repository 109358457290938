import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { _MAIN_WALLET_ID, AlertTypes, ApiKey, ApiUrl,LanguageKey, LanguageOption, SessionKey, WebUrl } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { map, some } from "lodash";
import ContentHeader from "../../../components/contentheader/header";
import {
	showResponseMessage,
	setBusy,
	setIdle,
	bindMember,
	showMessage,
} from "../../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../../util/ApiEngine.js";
import DynamicLoadTable from "components/custom/DynamicLoadTable";
import VIcon from "components/custom/Icon";
import { themeIcons } from "config/theme";
import {
	isObjectEmpty,
	numberWithCurrencyFormat,
	stringIsNullOrEmpty,
	createFormBody,
} from "../../../util/Util";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { 
	Modal, 
	ModalBody, 
	Tooltip, 
	Nav,
	NavItem,
	NavLink, 
	TabContent,
	TabPane,
	Alert
} from "reactstrap";
import Icon from "@mdi/react";
import classnames from "classnames";
import { mdiContentCopy } from "@mdi/js";
import { useForm } from "react-hook-form";

const RecentBigWinInfo = () => {
	const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
	let _location = useLocation();
	const _dispatch = useDispatch();
	const _history = useHistory();
	const memberData = useSelector((state) => state.appState.member);
	var { isLoggedIn } = useSelector((state) => state.authState);
	const _FIRST_TAB = 1;
	const _SECOND_TAB = 2;

	const [selectedGameName, setSelectedGameName] = useState("");
	const [masterProductId, setMasterProductId] = useState(0);
	const [isApp, setIsApp] = useState(false);
	const [appDeepLink, setAppDeepLink] = useState("");
	const [selectedGameHasOwnLobby, setSelectedGameHasOwnLobby] = useState(false);
	const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
	const [appUrl, setAppUrl] = useState("");
	const [bonusWalletBalance, setBonusWalletBalance] = useState(0);
	const [fromOptions, setFromOptions] = useState([]);
	const [toOptions, setToOptions] = useState([]);
	const [mpData, setMpData] = useState([]);
	const [appUsername, setAppUsername] = useState("");
	const [appPassword, setAppPassword] = useState("");
	const [showAppCred, setShowAppCred] = useState(false);
	const [isNewWindow, setIsNewWindow] = useState(false);
	const [showPregamePopup, setShowPregamePopup] = useState(false);
	const [bonus, setBonus] = useState(0);
	const [productGameId, setProductGameId] = useState(0);
	const [productCommRate, setProductCommRate] = useState([]);
	const [promotionList, setPromotionList] = useState([]);
	const [isBonusWallet, setIsBonusWallet] = useState(false)
	const [rolloverBalance, setRolloverBalance] = useState("");
	const [rolloverTarget, setRolloverTarget] = useState("");
	const [commissionInfo, setCommissionInfo] = useState({})
	const [toLobbyBtn, SetToLobbyBtn] = useState(false);
	const [popupBannerImage, setPopupBannerImage] = useState("");

	const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
	const [tempBonusWallet, setTempBonusWallet] = useState(0);
	const [tempMainWallet, setTempMainWallet] = useState(0);
	const [isPromoCleared, setIsPromoCleared] = useState(false);
	const [isBonusWalletRestored, setIsBonusWalletRestored] = useState(false);
	const [isActivedPromoExist, setIsActivePromoExist] = useState(false);
	const [isInitLoaded, setIsInitLoaded] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [isDisabled, setDisabled] = useState(false);

	const [isValidForPromotion, setIsValidForPromotion] = useState(false);
	const [showPromotionConfirmation, setShowPromotionConfirmation] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [selectedPromo, setSelectedPromo] = useState([]);
	const [showPromo, setShowPromo] = useState(false);
	const [isTransferSuccess, setIsTransferSuccess] = useState(false);
	const [gameActiveTab, setGameActiveTab] = useState(_FIRST_TAB);

	const [toGameAmount, setToGameAmount] = useState(0);
	const [promoId, setPromoId] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [promoShow, setPromoShow] = useState(false);

	const [showCopyAlert, setShowCopyAlert] = useState(false);
  	const _ALERT_TIMEOUT = 3000;

	const [transferShow, setTransferShow] = useState(false);
	const {
		handleSubmit: handleSubmitPopup,
		register: registerPopup,
		control: controlPopup,
		watch: watchPopup,
		getValues: getValuesPopup,
		setValue: setValuePopup,
		errors: errorsPopup,
		reset: resetPopup,
	  } = useForm();
	//special kip
	const [gameList, setGameList] = useState([]);
	const [promoCodeList, setPromoCodeList] = useState([]);

	let usernameRef = useRef();
	let passwordRef = useRef();

	useEffect(() => {
		if (isLoggedIn) {
		  //disable auto update memberWallet and bonus wallet
		  setInterval(() => tempUpdateUserData(), 30000);
		  tempUpdateUserData();
		}
	  }, [isLoggedIn]);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	
	function copyText(textType) {
		if (textType === "username") {
		  usernameRef.current.select();
		} else if (textType === "password") {
		  passwordRef.current.select();
		}
		document.execCommand("copy");
		//setShowCopyAlert(true);
		//setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
	  }

	  function copyBetText(label) {
		navigator.clipboard.writeText(label);
		setShowCopyAlert(true);
		setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
	  }
	
	  const submitPopupForm = async (data, e) => {
		_dispatch(setBusy());
	
		let params = {
		  fromWallet: _MAIN_WALLET_ID,
		  toWallet: masterProductId,
		  amount: toGameAmount,
		  depositPromotionProductId: promoId,
		};
	
		let responseJson = await ApiEngine.post(
		  ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
		  createFormBody(params)
		);
	
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
		  await updateWalletBalance(
			parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
		  );
		//   setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
		//   setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
		  setIsTransferSuccess(true);
		  setTransferShow(false);
		//   setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);
		  getRolloverProgress(masterProductId);
		  RefreshBalanceF(masterProductId);
		  e.target.reset();
		} else {
		  _dispatch(
			showResponseMessage(
			  responseJson[ApiKey._API_SUCCESS_KEY],
			  responseJson[ApiKey._API_MESSAGE_KEY]
			)
		  );
		}
		_dispatch(setIdle());
	  };

	async function getRestore() {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);

		_dispatch(
			showResponseMessage(
			responseJson[ApiKey._API_SUCCESS_KEY],
			responseJson[ApiKey._API_MESSAGE_KEY]
			)
		);
		tempUpdateUserData();
		_dispatch(setIdle());
	}

	async function getProductComRate(selectedGameName) {
		let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
		let data = [];
		let responseJson = await ApiEngine.get(apiUrl);
		if (
		  responseJson[ApiKey._API_SUCCESS_KEY] &&
		  responseJson[ApiKey._API_DATA_KEY].length > 0
		) {
		  data = responseJson[ApiKey._API_DATA_KEY].filter(
			(x) => x.productName === selectedGameName
		  );
	
		  if (data.length > 0) {
			setProductCommRate(data[0]);
		  }
		}
	}

	
	async function updateWalletBalance() {
		var responseJson = await ApiEngine.get(
		  ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
		  let tempFromOptions = [];
		  let tempToOptions = [];
		  responseJson[ApiKey._API_DATA_KEY].map((product) => {
			let option = {
			  label: product.productName,
			  value: product.productId,
			  amount: parseFloat(product.balance),
			};
			if (product.productId === _MAIN_WALLET_ID) {
			  tempFromOptions.push(option);
			} else if (product.productId === masterProductId) {
			//   let gameWalletBalance = parseFloat(product.balance);
			//   setGameWalletBalanceBeforeTransfer(gameWalletBalance);
			  tempToOptions.push(option);
			}
		  });
		  setFromOptions(tempFromOptions);
		  setToOptions(tempToOptions);
		}
	  }

	  async function RefreshBalanceF(masterProductId) {
		setMasterProductId(masterProductId);
		_dispatch(setBusy());
		var responseJson = await ApiEngine.get(
		  ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
		  "?masterProductId=" +
		  masterProductId
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
		//   setWalletList(responseJson[ApiKey._API_DATA_KEY]);
	
		  let tempFromOptions = [];
		  let tempToOptions = [];
	
		  responseJson[ApiKey._API_DATA_KEY].map((product) => {
			let option = {
			  label: product.productName,
			  value: product.productId,
			  amount: parseFloat(product.balance),
			};
			if (product.productId === _MAIN_WALLET_ID) {
			  tempFromOptions.push(option);
			} else if (product.productId === masterProductId) {
			  setBonus(product.bonus);
			//   setCommission(product.commission);
			//   setGameWalletBalanceBeforeTransfer(product.balance);
			//   if (product.masterProductContent !== null) {
			// 	setMasterProductContent(product.masterProductContent);
			//   } else {
			// 	setMasterProductContent("");
			//   }
			  tempToOptions.push(option);
			}
		  });
		  setFromOptions(tempFromOptions);
		  setToOptions(tempToOptions);
		  _dispatch(setIdle());
		  setDisabled(true);
		  setTimeout(() => setDisabled(false), 5000);
		}
	  }

	async function getVipCriteria(selectedGame){
		if(selectedGame == 'Slots'){
				selectedGame = 'Slot'
			}
		let responseJson = await ApiEngine.get(ApiUrl._API_GET_ALL_VIP_COMMISSION);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY];
			let res = [];
			for(let obj of data){
			for (let key of Object.keys(obj)) {
			
				if (key.includes(selectedGame)) {
				let title=''
				if(obj['content'].includes('Normal')){
					title = 'NORMAL'
				}else if(obj['content'].includes('Bronze')){
					title = 'BRONZE'
				}else if(obj['content'].includes('Silver')){
					title = 'SILVER'
				}else if(obj['content'].includes('Gold')){
					title = 'GOLD'
				}else if(obj['content'].includes('Platinum')){
					title = 'PLATINUM'
				}else if(obj['content'].includes('Diamond')){
					title = 'DIAMOND'
				}
				res.push({vipId:obj['vipId'],content:title,value:obj[key]});
				}
			}
			}
			setCommissionInfo(res)
		
		}
	}

	async function getRolloverProgress(masterProductId) {
		setRolloverBalance("");
		setRolloverTarget("");
		if (masterProductId != "0") {
			var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
			"?masterProductId=" +
			masterProductId
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY] != null) {
				if (responseJson[ApiKey._API_DATA_KEY].data != null) {
				if (
					responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"] !=
					"0" &&
					responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"] != "0"
				) {
					setRolloverBalance(
					responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
					);
					setRolloverTarget(
					responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
					);
				}
				}
			}
			}
		}
	}
	const getPromotion = async (id, languageId) => {

		let apiUrl = `${ApiUrl._API_GET_RELATED_DEPOSIT_PROMOTION_BY_PRODUCT_ID}`;
		if (
			localStorage.getItem(SessionKey._LANGUAGE) &&
			localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
		) {
			if (
				LanguageOption.find(
					(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
				) != undefined
			) {
				apiUrl +=
					"?langId=" +
					LanguageOption.find(
						(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
					).languageId;
			} else {
				apiUrl += "?langId=1";
			}
		} else {
			apiUrl += "?langId=1";
		}

		apiUrl += "&productId=" + id;

		let responseJson = await ApiEngine.get(apiUrl);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setPromotionList(responseJson[ApiKey._API_DATA_KEY]);
			let isBonus = some(responseJson[ApiKey._API_DATA_KEY], 'isActived');
			setIsBonusWallet(isBonus)
		}
	};

	const tempUpdateUserData = async () => {
		if (isLoggedIn) {
	
		  var responseJson2 = await ApiEngine.get(ApiUrl._API_UPDATE_TEMP_WALLET); //, createMultiPartFormBody(params));
	
		  let walletResponse = await ApiEngine.get(
			ApiUrl._API_GET_MEMBER_WALLET_BALANCE
		  );
		  if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
			var totalwalletbalance = walletResponse[ApiKey._API_DATA_KEY].primaryCredit + walletResponse[ApiKey._API_DATA_KEY].tempPrimaryCredit;
			var totalbonuswallet = walletResponse[ApiKey._API_DATA_KEY].bonusWallet + walletResponse[ApiKey._API_DATA_KEY].tempBonusWallet;
			
			
			setTempMainWallet(walletResponse[ApiKey._API_DATA_KEY].tempPrimaryCredit);
			setTempBonusWallet(walletResponse[ApiKey._API_DATA_KEY].tempBonusWallet);
			setCurrentWalletBalance(totalwalletbalance);       
			setBonusWalletBalance(totalbonuswallet);        
			setIsPromoCleared(walletResponse[ApiKey._API_DATA_KEY].isPromoCleared)
			setIsBonusWalletRestored(walletResponse[ApiKey._API_DATA_KEY].isBonusWalletRestored)
			setIsValidForPromotion(walletResponse[ApiKey._API_DATA_KEY].isAnyTransactionValidForPromo)
			setIsActivePromoExist(walletResponse[ApiKey._API_DATA_KEY].isActivedPromoExist)
			setIsInitLoaded(true);
		  }
		}
	  };

	async function startGame(
		gameId,
		hasOwnLobby,
		masterProductId,
		gameName,
		isSeamless,
		isApp,
		appUrl,
		appDeepLink,
		isNewWindow,
		packageNameKeyword,
		categoryTitle
		
	  ) {
		setSelectedGameName(gameName);
		setMasterProductId(masterProductId);
		setIsApp(isApp);
		setAppDeepLink(appDeepLink);
		setSelectedGameHasOwnLobby(hasOwnLobby);
		setSelectedGameIsSeamless(isSeamless);
		if (isApp && !stringIsNullOrEmpty(appUrl)) {
		  setAppUrl(appUrl);
		}
		if (hasOwnLobby !== true) {
		  if (isSeamless == false) {
			_dispatch(setBusy());
			var responseJson = await ApiEngine.get(
			  ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
			  "?masterProductId=" +
			  masterProductId
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			//   setWalletList(responseJson[ApiKey._API_DATA_KEY]);
	
			  let tempFromOptions = [];
			  let tempToOptions = [];
	
			  responseJson[ApiKey._API_DATA_KEY].map((product) => {
				let option = {
				  label: product.productName,
				  value: product.productId,
				  amount: parseFloat(product.balance),
				};
				if (product.productId === _MAIN_WALLET_ID) {
				  tempFromOptions.push(option);
				} else if (product.productId === masterProductId) {
				  setBonus(product.bonus);
				//   setCommission(product.commission);
				//   setGameWalletBalanceBeforeTransfer(product.balance);
				//   if (product.masterProductContent !== null) {
				// 	setMasterProductContent(product.masterProductContent);
				//   } else {
				// 	setMasterProductContent("");
				//   }
				  tempToOptions.push(option);
				}
			  });
			  setFromOptions(tempFromOptions);
			  setToOptions(tempToOptions);
	
			  var mpJson = await ApiEngine(
				ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
				"?masterProductId=" +
				masterProductId
			  );
			  if (mpJson[ApiKey._API_SUCCESS_KEY]) {
				let data = mpJson[ApiKey._API_DATA_KEY];
				setMpData(data);
				if (window.ReactNativeWebView && isApp) {
				  window.ReactNativeWebView.postMessage(
					JSON.stringify({
					  action: "deeplink",
					  url: appUrl,
					  deeplink: appDeepLink
						.replace("[username]", data["username"])
						.replace("[password]", data["password"]),
					  packageKeyword: packageNameKeyword,
					})
				  );
				}
				setAppUsername(data["username"]);
				setAppPassword(data["password"]);
			  }
			}
			//if (
			//  gameName == "Pussy888" ||
			//  gameName == "Mega888" ||
			//  gameName == "Evo888"
			//) {
			//  var responseJson = await ApiEngine.get(
			//    ApiUrl._API_GET_APP_LOGIN_CRED + "?productId=" + masterProductId
			//  );
			//  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			//    setShowAppCred(true);
			//    setAppUsername(responseJson[ApiKey._API_DATA_KEY].username);
			//    setAppPassword(responseJson[ApiKey._API_DATA_KEY].password);
			//  }
			//}
	
			if (
			  gameName == "Pussy888" ||
			  gameName == "Mega888" ||
			  gameName == "Evo888" ||
			  gameName == "918Kiss"
			) {
			  setShowAppCred(true);
			}
	
			setProductGameId(gameId);
			var isNewWindowJson = await ApiEngine(
			  ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
			);
			setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
			setShowPregamePopup(true);
			getProductComRate(gameName);
			getVipCriteria(categoryTitle);
			getRolloverProgress(masterProductId);
			getPromotion(masterProductId)
			_dispatch(setIdle());
		  } else {
			window.open(
			  ApiUrl._API_START +
			  "?gameId=" +
			  gameId +
			  "&isApp=" +
			  (isApp ? 1 : 0) +
			  "&device=" +
			  (window.innerWidth <= 768 ? "m" : "d")
			);
		  }
		} else {
		  if (isSeamless == false) {
			_dispatch(setBusy());
			var responseJson = await ApiEngine.get(
			  ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
			  "?masterProductId=" +
			  masterProductId
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			//   setWalletList(responseJson[ApiKey._API_DATA_KEY]);
	
			  let tempFromOptions = [];
			  let tempToOptions = [];
	
			  responseJson[ApiKey._API_DATA_KEY].map((product) => {
				let option = {
				  label: product.productName,
				  value: product.productId,
				  amount: parseFloat(product.balance),
				};
				if (product.productId === _MAIN_WALLET_ID) {
				  tempFromOptions.push(option);
				} else if (product.productId === masterProductId) {
				  setBonus(product.bonus);
				//   setCommission(product.commission);
				//   setGameWalletBalanceBeforeTransfer(product.balance);
				//   if (product.masterProductContent !== null) {
				// 	setMasterProductContent(product.masterProductContent);
				//   } else {
				// 	setMasterProductContent("");
				//   }
				  tempToOptions.push(option);
				}
			  });
			  setFromOptions(tempFromOptions);
			  setToOptions(tempToOptions);
	
			  var mpJson = await ApiEngine(
				ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
				"?masterProductId=" +
				masterProductId
			  );
			  if (mpJson[ApiKey._API_SUCCESS_KEY]) {
				let data = mpJson[ApiKey._API_DATA_KEY];
				setMpData(data);
			  }
			}
	
			var isNewWindowJson = await ApiEngine(
			  ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
			);
			setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
			setShowPregamePopup(true);
			getProductComRate(gameName);
			getVipCriteria(categoryTitle);
			setProductGameId(gameId);
			SetToLobbyBtn(true);
			getRolloverProgress(masterProductId);

			_history.push(
			  WebUrl._URL_GAME_LOBBY +
			  "?name=" +
			  gameName +
			  "&id=" +
			  masterProductId +
			  "&languageId=" +
			  memberData["languageSetting"]
			);
			_dispatch(setIdle());
		  }
		}
	  }


	// async function setTransactionValidForPromo() {
	// 	_dispatch(setBusy());
	// 	let responseJson = await ApiEngine.get(
	// 		ApiUrl._API_UPDATE_TRANSACTION_VALID_FOR_PROMO
	// 	);
	// 	if (responseJson[ApiKey._API_SUCCESS_KEY]) {
	// 		getPromotion(props.id, props.languageId);

	// 		setIsValidForPromotion(false);
	// 	}
	// 	_dispatch(setIdle());
	// }
	//used for fake seamless
	async function startGame2(gameId, gameName, isSeamless, isNewWindow) {
		if (!window.ReactNativeWebView) {
			window.open(
				`${process.env.REACT_APP_ENDPOINT_URL || ""}${
					ApiUrl._API_START
				}?gameId=${gameId}&isApp=0&device=${
					window.innerWidth <= 768 ? "m" : "d"
				}`
			);
		} else {
			if (isNewWindow === true) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						url: `${
							process.env.REACT_APP_ENDPOINT_URL || window.location.origin
						}${ApiUrl._API_START}?gameId=${gameId}&device=${
							window.innerWidth <= 768 ? "m" : "d"
						}`,
					})
				);
			} else {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						url: `${
							process.env.REACT_APP_ENDPOINT_URL || window.location.origin
						}${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
					})
				);
			}
		}
	}
	
    return(
	<>
			{/* {showPromotionConfirmation && (
				<SweetAlert
					showCancel
					confirmBtnText={t("CONTINUE")}
					cancelBtnText={t("CANCEL")}
					confirmBtnBsStyle="info"
					icon
					onConfirm={() => {
						setShowPromotionConfirmation(false);
						setTransactionValidForPromo();
						startGame2(
							selectedGame.id,
							selectedGame.gameName,
							selectedGame.sSeamless,
							selectedGame.isNewWindow
						);
					}}
					onCancel={() => {
						setShowPromotionConfirmation(false);
						_history.push(WebUrl._URL_FS_PROMOTION);
					}}
					custom
					style={{
						backgroundColor: "#05102F",
						opacity: 0.9,
						marginTop: "68%",
					}}
					cancelBtnBsStyle="#05102F"
					customIcon={require("../../../../assets/img/home/new/info-icon.png")}
				>
					<span className="sweet-alert-custom-text">
						{t("PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME")}
					</span>
				</SweetAlert>
			)} */}

		{showConfirmation && (
          <SweetAlert
            showCancel
            confirmBtnText={t("YES")}
            cancelBtnText={t("NO")}
            confirmBtnBsStyle="info"
            icon
            onConfirm={() => {
              setShowConfirmation(false);
              getRestore();
            }}
            onCancel={() => {
              setShowConfirmation(false);
            }}
            custom
            style={{
              backgroundColor: "#05102F",
              opacity: 0.9,
              marginTop: "68%",
            }}
            cancelBtnBsStyle="#05102F"
            customIcon={require("../../../assets/img/home/new/info-icon.png")}
          >
            <span className="sweet-alert-custom-text">
              {t("RESTORE_WALLETS")}
            </span>
          </SweetAlert>
        )}

		<div className="all-section-box cover-90">
            <ContentHeader enableBackButton={true} title={t("RECENT_BIG_WIN")} />

            <DynamicLoadTable
				// returnRecord={getRecordFromDynamicLoad}
				pageSize={20}
				apiQuery={ApiUrl._API_GET_BIG_WIN}
				isTable={true}
				searchEnabled={true}
				render={(item, index) => {
					return (
						<div key={index} className="accordion-per-item-box mb-3">
							<div
								className="accordion-box collapsed"
								data-toggle="collapse"
								data-target={"#drop" + index}
								aria-expanded="false"
							>
								<div className="accordion-clicker-box align-items-center">
									<div className="d-flex align-items-center">
										<img src={item.image} alt={item.GameName} />
										<div className="font14 f-weight-500 ml-3 mb-1">
											{item.username}
										</div>
										{/* <div className="font12 text-lgray2">
											{moment(item["date"], "MM/DD/YYYY hh:mm:ss").format(
												"DD-MM-YYYY HH:mm:ss"
											)}
										</div> */}
									</div>
									<div>
										<div className="font14 f-weight-500 accordion-drop-icon">
											
											<div
												className="amount"
											>
												${numberWithCurrencyFormat(
													parseFloat(item.winning),
													2,
													true
												)} 
											</div>
											<VIcon
												icon={themeIcons.tableCoin}
												className="theme-icon-size-20 coin"
											/>
											<div>
												<div className="img1">
													<VIcon
														icon={themeIcons.angleDown}
														className="theme-icon-size-20"
													/>
												</div>
												<div className="img2">
													<VIcon
														icon={themeIcons.angleUp}
														className="theme-icon-size-20"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="collapse" id={"drop" + index}>
								<div className="accordion-drop-box big-win-box">
									<div className="d-flex align-items-center justify-content-center flex-column mt-2">
										<div className="font14 f-weight-700 mb-1">
											{t("TOTAL_WINNINGS")}
										</div>
										<div className="amount">
											${numberWithCurrencyFormat(
												parseFloat(item.winning),
												2,
												true
											)} 
										</div>
										<div className="bet-detail mt-2">
											<div className="left">
												<div className="label font12">
													{t("BET_AMOUNT")}
												</div>
												<div className="font12">
													{numberWithCurrencyFormat(
													parseFloat(item.amount),
													2,
												true
											)} 
												</div>
											</div>
											<div className="right">
												<div className="label font12">
													{t("PAYOUT")}
												</div>
												<div className="font12">
													{item.winRate} x
												</div>
											</div>
										</div>
										<div className="mt-3">
											{moment(item.date).format("YYYY-MM-DD HH:mm:ss")}
										</div>
										<div className="mt-1">
											{t("BET")}{" "}ID: 
											<VIcon
												icon={themeIcons.verified}
												className="theme-icon-size-18 ml-2 mr-2"
												style={{color: "#00D40A"}}
											/> 
											{moment(item.date).format('YYYYMMDD')}{item.id}
											
											{
												showCopyAlert ? <span className="label font14 ml-2">{t("COPIED")}</span> : 
												<VIcon
													icon={themeIcons.copy}
													className="theme-icon-size-20 ml-2"
													onClick={() => {
														copyBetText(moment(item.date).format('YYYYMMDD') + item.id)
													}}

												/> 
											}
										</div>
										<div className="bottom mt-4 pl-2 pr-2">
											<div className="left">
												<div>{item.gameName}</div>
												<div className="label font14">{item.productName}</div>
											</div>
											<a className="right link" 
												onClick={() => {
													if (isLoggedIn) {
														setPopupBannerImage(
														  item.popupBannerImage
														);
														if (item.gameUm !== true) {
														  startGame(
															item.id,
															item.hasOwnLobby,
															item.masterProductId,
															item.gameName,
															item.masterProductIsSeamless,
															item.isApp,
															item.appUrl,
															item.appDeepLink,
															item.isNewWindow,
															item.packageNameKeyword,
															item.categoryTitle
														  );
														}
													  } else {
														_dispatch(
														  showMessage({
															type: AlertTypes._WARNING,
															content: t("PLEASE_LOGIN_FIRST"),
														  })
														)
													  }
												}}
											>
												{t("PLAY_NOW")}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			/>

        </div>
		
		<Modal
			id="dashboard-pregame-popup"
			fade={false}
			contentClassName="modal-brand modal-bottom reload-bank-modal withdraw-accounts-modal"
			isOpen={showPregamePopup}
			toggle={() => {
			setShowPregamePopup(false);
			SetToLobbyBtn(false);
			setShowAppCred(false);
			}}
			centered
		>
			<ModalBody>
			<div id="game-lobby-new">
				<div className="top-title-right mb-3">
				<div className="content-header home-header shared-header modern-header">
					<div className="top-header">
					<div>
						<div>
						<button
							type="button"
							className="btn btn-icon"
							onClick={() => {
							setShowPregamePopup(false);
							SetToLobbyBtn(false);
							setShowAppCred(false);
							}}
						>
							<i className="fa fa-angle-left"></i>
						</button>
						</div>
						<div
						className="text-lblue1 text-underline"
						onClick={() => {
							setShowPregamePopup(false);
							SetToLobbyBtn(false);
							setShowAppCred(false);
						}}
						style={{ cursor: "pointer" }}
						>
						{t("CANCEL")}
						</div>
					</div>
					</div>
				</div>
				</div>
				<div className="">
				<h3 className="mb-3">{selectedGameName}</h3>
				<div className="gamelobby-banner">
					{!stringIsNullOrEmpty(popupBannerImage) ? (
					<img
						src={popupBannerImage}
						className="img-100 border-radius-10"
						alt="banner"
					/>
					) : (
					<img
						src={require("../../../assets/img/game/jj-banner.png")}
						className="img-100 border-radius-10"
						alt="banner"
					/>
					)}
				</div>
				</div>
				{!selectedGameIsSeamless && (
				<>
					<div className="wallet-notice">
					<img src={require('../../../assets/img/notice-icon.png')}/>
					{isBonusWallet? t('NOTICE_BONUS_WALLET'):t('NOTICE_MAIN_WALLET')}
					</div>
					<div className="home-balance-com-box mt-3">
					<div className={`hbc-box bg-lblue ${isBonusWallet? "":"box-active"}`}>
						<div className="first">
						<div className="hbc-label font12 text-lgray4">
							{t("MAIN_WALLET")}
						</div>
						<div className="hbc-balance font14 f-weight-500">
							{fromOptions.length > 0
							? numberWithCurrencyFormat(currentWalletBalance, 2)
							: ""}
						</div>
						</div>
						<div className="second-wrapper">
						<div className="second">
							<VIcon
							icon={themeIcons.refresh}
							className="theme-icon-blue-size-15"
							onClick={() => {
								setShowConfirmation(true);
							}}
							disabled={isDisabled}
							/>
						</div>
						{/*<div className="second" style={{ cursor: "pointer" }}>*/}
						{/*  <VIcon*/}
						{/*    icon={themeIcons.dashboardBalanceDeposit}*/}
						{/*    className="theme-icon-blue-size-15"*/}
						{/*    onClick={() => {*/}
						{/*      getDepositPromotion(masterProductId);*/}
						{/*      setTransferShow(true);*/}
						{/*    }}*/}
						{/*  />*/}
						{/*</div>*/}
						</div>
					</div>
					<div className={`hbc-box bg-lblue ${isBonusWallet? "box-active":""}`}>
						<div className="first">
						<div
							className="hbc-label font12 text-lgray4"
							style={{ whiteSpace: "nowrap" }}
						>
							{t("BONUS_WALLET")}
						</div>
						<div className="hbc-balance font14 f-weight-500">
							{toOptions.length > 0
							? numberWithCurrencyFormat(bonusWalletBalance, 2)
							: ""}
						</div>
						</div>
						<div className="second-wrapper">
						<div className="second">
							<VIcon
							icon={themeIcons.refresh}
							className="theme-icon-blue-size-15"
							onClick={() => {
								setShowConfirmation(true);
							}}
							disabled={isDisabled}
							/>
						</div>
						{/*<div className="second" style={{ cursor: "pointer" }}>*/}
						{/*  <VIcon*/}
						{/*    icon={themeIcons.dashboardBalanceDeposit}*/}
						{/*    className="theme-icon-blue-size-15"*/}
						{/*    onClick={() => {*/}
						{/*      getDepositPromotion(masterProductId);*/}
						{/*      setTransferShow(true);*/}
						{/*    }}*/}
						{/*  />*/}
						{/*</div>*/}
						</div>
					</div>
					</div>
					<div className="home-balance-com-box mt-3">
					<div className="hbc-box bg-lblue">
						<div className="first">
						<div className="hbc-label font12 text-lgray4">
							{t("COMMISSION")}
						</div>
						<div className="hbc-balance font14 f-weight-500">
							{!isObjectEmpty(productCommRate)
							? productCommRate.playerComm.toFixed(2)
							: "0.00"}
							%
						</div>
						</div>
						<div className="second-wrapper">
							<div className="second">
								<VIcon
								icon={themeIcons.dashboardCommission}
								className="theme-icon-blue-size-20"
								/>
							</div>
						</div>
					</div>
					<div className="hbc-box bg-lblue">
						<div className="first">
						<div className="hbc-label font12 text-lgray4">
							{t("VIP_COMMISSION")}   
							<Tooltip
							className="wallet-tooltip"
							placement="bottom"
							isOpen={tooltipOpen}
							autohide={false}
							target="tooltipInfo"
							toggle={toggle}
							>
							{
								map(commissionInfo, (item)=>{
								return (
									<div>
									{t(item.content)} : {item.value.toFixed(2)}%
									</div>
								)
								})
							}
							</Tooltip>
							<VIcon 
								id="tooltipInfo" 
								icon={themeIcons.info}
								className="wallet-info-icon"
							/>
							{/* <img id="tooltipInfo" className="ml-1" width={'13px'} src={require('../../../assets/img/info.svg')}/> */}
						</div>
						<div className="hbc-balance font14 f-weight-500">
							{productCommRate.categoryName == "Slots"
							? memberData["vipInfo"]?.["rebateSlot"].toFixed(2)
							: productCommRate.categoryName == "Sports"
							? memberData["vipInfo"]?.["rebateSport"].toFixed(2)
							: productCommRate.categoryName == "Fish"
							? memberData["vipInfo"]?.["rebateFish"].toFixed(2)
							: productCommRate.categoryName == "Lottery"
							? memberData["vipInfo"]?.["rebateLottery"].toFixed(2)
							: memberData["vipInfo"]?.["rebateCasino"].toFixed(2)}
							%
						</div>
						</div>
						<div className="second-wrapper">
						<div className="second">

							<div className="vip-icon-wallet" style={{
							backgroundImage: `url("${memberData["vipInfo"]?.["vipModel"]?.["icon"]}")`,
							}}></div>
							{/* {memberData["vipInfo"]?.["vipId"] > 1 ? (

							
							<VIcon
								icon={themeIcons.dashboardCommission}
								className="theme-icon-blue-size-20"
							/>
							) : (
							<VIcon
								icon={themeIcons.lockClosed}
								className="theme-icon-blue-size-20"
							/>
							)} */}
						</div>
						</div>
					</div>
					</div>
					{rolloverBalance != "" && rolloverTarget != "" && (
					<div>
						<div className="mb-1 mt-4" style={{ margineTop: "30px" }}>
						<span className="hbc-label font12 text-lgray4">
							{t("ROLLOVER")}
						</span>
						</div>
						<div className="rollover-balance-box">
						<div className="progress-bar-box-deposit-promotion">
							<span
							className="progress-identifier"
							style={{
								width:
								rolloverTarget == rolloverBalance
									? "0%"
									: ((rolloverTarget - rolloverBalance) /
									rolloverTarget) *
									100 +
									"%",
							}}
							></span>
						</div>
						<span className="font10 text-lgray2">
							{numberWithCurrencyFormat(
							parseFloat(rolloverTarget - rolloverBalance),
							2,
							true
							)}{" "}
							/{" "}
							{numberWithCurrencyFormat(
							parseFloat(rolloverTarget),
							2,
							true
							)}
						</span>
						</div>
					</div>
					)}
					{promotionList.length > 0 &&
					<div className="promotion-container mt-4">
						<h4>{t("PROMOTIONS")}</h4>
						<div className="promotion-content promotion-content-flex">
						{promotionList.length > 0 && map(promotionList, (item2, index) => {
							return (
							<div className={`fs-promotion-item ${item2.isActived? "is-active":""}`} onClick={() => {
								setSelectedPromo(item2)
								setShowPromo(true);

							}} >
								<img
								src={item2.image}
								className={(item2.isEntitled && item2.isPromoCleared && isValidForPromotion) || item2.isActived ? "img-responsive" : "img-responsive unavailable-image"}
								alt={item2.name}
								/>
							</div>
							)
						})}
						</div>
					</div>
					}
					{selectedGameName == "Pussy888" ||
					selectedGameName == "Mega888" ||
					selectedGameName == "Evo888" ||
					selectedGameName == "918Kiss" ? (
					<div className="mt-3">
						<p
						style={{
							color: "#ff1a1a",
							fontSize: "12px",
							lineHeight: "17px",
						}}
						>
						{t("PRODUCT_ROLLOVER_MESSAGE")}
						</p>
					</div>
					) : null}
					{isTransferSuccess && (
					<div className="mt-3 reminder-green">
						<div className="first">
						<div className="first">
							<img
							src={require("../../../assets/img/new_icons/green.png")}
							className="img-responsive"
							alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">
							{t("TRANSFER_BALANCE_SUCCESS")}!
							</p>
							<p className="font12 mb-0">
							{t("YOUR_ACCOUNT_BALANCE_IS_UPDATED")}.
							</p>
						</div>
						</div>
						{
						<div
							className="second"
							onClick={() => setIsTransferSuccess(false)}
							style={{ cursor: "pointer" }}
						>
							<p className="font12 mb-0">{t("OKAY")}</p>
						</div>
						}
					</div>
					)}

					{!isApp || (isApp && appDeepLink != "") ? (
					<>
						{!toLobbyBtn ? (
						<button
							type="button"
							onClick={() => {
							if (isValidForPromotion) {
								setShowPromotionConfirmation(true);
							} else {
								startGame2(productGameId, selectedGameName, selectedGameIsSeamless, isNewWindow)
							}
							//if (!window.ReactNativeWebView) {
							//  window.open(
							//    ApiUrl._API_START +
							//    "?gameId=" +
							//    productGameId +
							//    "&isApp=0" +
							//    "&device=" +
							//    (window.innerWidth <= 768 ? "m" : "d")
							//  );
							//} else {
							//  if (isApp) {
							//    window.ReactNativeWebView.postMessage(
							//      JSON.stringify({
							//        action: "deeplink",
							//        url: appUrl,
							//        deeplink: appDeepLink
							//          .replace("[username]", mpData["username"])
							//          .replace("[password]", mpData["password"]),
							//      })
							//    );
							//  } else {
							//    if (isNewWindow === true) {
							//      window.ReactNativeWebView.postMessage(
							//        JSON.stringify({
							//          url: `${process.env.REACT_APP_ENDPOINT_URL ||
							//            window.location.origin
							//            }${ApiUrl._API_START
							//            }?gameId=${productGameId}&isApp=0&device=${window.innerWidth <= 768 ? "m" : "d"
							//            }`,
							//        })
							//      );
							//    } else {
							//      window.ReactNativeWebView.postMessage(
							//        JSON.stringify({
							//          url: `${process.env.REACT_APP_ENDPOINT_URL ||
							//            window.location.origin
							//            }${WebUrl._URL_GAME_PAGE
							//            }?gameId=${productGameId}&isApp=0&device=${window.innerWidth <= 768 ? "m" : "d"
							//            }`,
							//          selectedTheme: _USER_DATA.selectedTheme,
							//        })
							//      );
							//    }
							//  }
							/*}*/
							}}
							className="btn btn-custom font16 mt-4"
						>
							{t("START_GAME")}
						</button>
						) : (
						<></>
						)}
					</>
					) : (
					<button
						type="button"
						onClick={() => {
						if (window.ReactNativeWebView) {
							window.ReactNativeWebView.postMessage(
							JSON.stringify({ url: appUrl })
							);
						} else {
							window.open(appUrl);
						}
						}}
						className="btn btn-custom font16 mt-4"
					>
						{t("DOWNLOAD_APP")}
					</button>
					)}
					{showAppCred && (
					<div className="report-final-balance-box mt-3">
						<div
						className="earn-stat-title mt-2"
						style={{ position: "relative" }}
						>
						<div className="details-label">
							{t("USERNAME") + " : "}
							<input
							className="app-login-cred-text-box ml-2"
							type="text"
							value={appUsername}
							ref={usernameRef}
							readOnly
							/>
						</div>

						<div
							onClick={() => copyText("username")}
							style={{ cursor: "pointer" }}
						>
							<Icon path={mdiContentCopy} size={1} color="#cbd5e1" />
						</div>
						</div>
						<div
						className="earn-stat-title mt-2"
						style={{ position: "relative" }}
						>
						<div className="details-label">
							{t("PASSWORD") + " : "}
							<input
							className="app-login-cred-text-box ml-2"
							type="text"
							value={appPassword}
							ref={passwordRef}
							readOnly
							/>
						</div>

						<div
							onClick={() => copyText("password")}
							style={{ cursor: "pointer" }}
						>
							<Icon path={mdiContentCopy} size={1} color="#cbd5e1" />
						</div>
						</div>
					</div>
					)}
				</>
				)}
				{selectedGameHasOwnLobby &&
				gameList &&
				gameList["data"] &&
				gameList["data"].length > 0 && (
					<>
					{gameList["data"].filter((x) => x.isRecommended) > 0 && (
						<div className="mt-3">
						<h3>Hot Games</h3>
						<div className="home-service-provider mt-3 mb-3">
							{map(
							gameList["data"].filter((x) => x.isRecommended),
							(item, index) => {
								return (
								<div key={index} className="hsp-item">
									<a>
									<img
										src={item.image}
										className="img-100"
										alt="icon"
									/>
									<div className="gamelist-name text-white">
										{item.gameName}
									</div>
									</a>
								</div>
								);
							}
							)}
						</div>
						</div>
					)}
					{gameList["data"].filter((x) => !x.isRecommended).length >
						0 && (
						<div className="mt-3">
							<h3>More Games</h3>
							{ }
							<Nav className="nav nav-tabs mb-3 mt-3" id="my-tabs">
							<NavItem>
								<NavLink
								className={classnames({
									active: gameActiveTab === _FIRST_TAB,
								})}
								onClick={(e) => {
									e.preventDefault();
									setGameActiveTab(_FIRST_TAB); //todo
								}}
								style={{ cursor: "pointer" }}
								>
								All Games
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
								className={classnames({
									active: gameActiveTab === _SECOND_TAB,
								})}
								onClick={(e) => {
									e.preventDefault();
									setGameActiveTab(_SECOND_TAB);
								}}
								style={{ cursor: "pointer" }}
								>
								Slots
								</NavLink>
							</NavItem>
							</Nav>
							<div className="search-box">
							<div className="search-with-icon-box">
								<div className="search-icon">
								<VIcon
									icon={themeIcons.search}
									className="theme-icon-size-20"
								/>
								</div>
								<input type="text" placeholder="Search games..." />
							</div>
							</div>
							<TabContent activeTab={gameActiveTab}>
							<TabPane tabId={_FIRST_TAB}>
								<div className="home-service-provider mt-4 mb-3">
								{gameList["data"].filter((x) => !x.isRecommended)
									.length > 0 &&
									map(
									gameList["data"].filter(
										(x) => !x.isRecommended
									),
									(item, index) => {
										return (
										<div key={index} className="hsp-item">
											<a
											onClick={() => { 
												if (isValidForPromotion) {
												setShowPromotionConfirmation(true);
												} else {
												startGame2(productGameId, selectedGameName, selectedGameIsSeamless, isNewWindow)
												}
												
											}}
											>
											<img
												src={item.image}
												className="img-100 border-radius-10"
												alt="icon"
											/>
											<div className="gamelist-name text-white">
												{item.gameName}
											</div>
											</a>
										</div>
										);
									}
									)}
								</div>
							</TabPane>
							<TabPane tabId={_SECOND_TAB}>
								<div className="home-service-provider mt-4 mb-3">
								{gameList["data"].filter((x) => !x.isRecommended)
									.length > 0 &&
									map(
									gameList["data"].filter(
										(x) => !x.isRecommended
									),
									(item, index) => {
										return (
										<div key={index} className="hsp-item">
											<a href={item.link}>
											<img
												src={item.image}
												className="img-100"
												alt="icon"
											/>
											<div className="gamelist-name text-white">
												{item.gameName}
											</div>
											</a>
										</div>
										);
									}
									)}
								</div>
							</TabPane>
							</TabContent>
						</div>
						)}
					</>
				)}
				<Modal
				fade={false}
				contentClassName={
					"modal-brand modal-bottom reload-bank-modal withdraw-accounts-modal"
				}
				isOpen={transferShow}
				toggle={() => {
					setTransferShow(false);
				}}
				centered
				>
				<ModalBody>
					<form onSubmit={handleSubmitPopup(submitPopupForm)}>
					<div className="top-title-right mb-3">
						<div className="content-header home-header shared-header modern-header">
						<div className="top-header">
							<div>
							<div>
								<button
								type="button"
								className="btn btn-icon"
								onClick={() => {
									setTransferShow(false);
									setToGameAmount(0);
									setPromoId("");
								}}
								>
								<i className="fa fa-angle-left"></i>
								</button>
							</div>
							<div
								className="text-lblue1 text-underline"
								onClick={() => {
								setTransferShow(false);
								setToGameAmount(0);
								setPromoId("");
								}}
								style={{ cursor: "pointer" }}
							>
								{t("CANCEL")}
							</div>
							</div>
						</div>
						</div>
					</div>
					<h5 className="mb-3">{t("TRANSFER_BALANCE")}</h5>
					<div className="mb-1 mt-3">
						<span className="font12 text-lgray2">
						{t("AMOUNT_TO_TRANSFER")}
						</span>
					</div>
					<div className="urr-amount-box">
						<div>
						<input
							type="text"
							name="amount"
							value={Number(toGameAmount).toFixed(2)}
							placeholder={numberWithCurrencyFormat(
							parseFloat(toGameAmount),
							2,
							true
							)}
							className="input-with-balance"
							onChange={(e) => {
							let amount = e.target.value
								.replace(
								/[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?(kr|$||)]*/g,
								""
								)
								.trim();

							let value = Number(parseFloat(amount) / 100).toFixed(
								2
							);
							if (
								parseFloat(value) >
								parseFloat(fromOptions[0].amount)
							) {
								value = fromOptions[0].amount;
							}
							e.target.setSelectionRange(
								value.length,
								value.length
							);
							setInputValue(value);
							setToGameAmount(value);
							}}
							disabled={
							fromOptions.length > 0 &&
								fromOptions[0].amount >
								numberWithCurrencyFormat(parseFloat(0), 2, true)
								? ""
								: "disabled"
							}
						/>
						</div>
						<span className="font10 text-lgray2">
						{t("AVAILABLE_BALANCE")}
						{fromOptions.length > 0
							? numberWithCurrencyFormat(fromOptions[0].amount, 2)
							: ""}
						</span>
					</div>
					<span className="font12 text-lgray2 justify-center mt-2">
						{t("Min")}:{" "}
						{promoId != ""
						? numberWithCurrencyFormat(
							promoCodeList.filter(
							(x) => x.id == parseInt(promoId)
							)[0].minDeposit,
							2,
							true
						)
						: numberWithCurrencyFormat(1, 2, true)}
					</span>
					<div
						className="all-box-with-icon mt-2"
						onClick={() => {
						setPromoShow(true);
						}}
						style={{ cursor: "pointer" }}
					>
						<div className="first">
						<div>
							<VIcon
							icon={themeIcons.dashboardCommission}
							className="theme-icon-blue-size-20"
							/>
						</div>
						<div className="font14 f-weight-500 text-lblue">
							{promoId == ""
							? t("PROMO_CODE")
							: promoCodeList.filter(
								(x) => x.id == parseInt(promoId)
							)[0].name}
						</div>
						</div>
						<div>
						<VIcon
							icon={themeIcons.angleRight}
							className="theme-icon-blue-size-20"
						/>
						</div>
					</div>
					{promoId != null &&
						promoCodeList
						.filter((x) => x.id == parseInt(promoId))
						.map((item, index) => {
							return (
							<div
								key={index}
								className="reports-stats-box mb-3 mt-4"
							>
								<div className="ro-box-1-1">
								<div className="earn-stat-title mb-1">
									<div className="first-title">
									{t("BONUS")} %
									</div>
									<div className="d-flex align-items-center"></div>
								</div>
								<div
									className="earn-stat-number mb-1"
									style={{ textAlign: "right" }}
								>
									{" "}
									{item.bonus}
								</div>
								</div>

								<div className="ro-box-1-1">
								<div className="earn-stat-title mb-1">
									<div className="first-title">Rollover</div>
								</div>
								<div
									className="earn-stat-number mb-1"
									style={{ textAlign: "right" }}
								>
									{" "}
									x{item.rollover}
								</div>
								</div>
							</div>
							);
						})}

					<div className="mt-2">
						{bonus > 0 && (
						<>
							<span className="font12 text-lgray2">
							{t("BONUS")}:{" "}
							{numberWithCurrencyFormat(parseFloat(bonus), 2, true)}
							%
							</span>
							<br />
						</>
						)}
					</div>
					<div>
						<button
						type="submit"
						className={
							inputValue > 0
							? "btn btn-custom font16 mt-4"
							: "btn btn-custom font16 mt-4 disabled"
						}
						>
						{t("TRANSFER_TO_WALLET")}
						</button>
					</div>
					</form>
				</ModalBody>
				</Modal>

				<Modal
				fade={false}
				contentClassName={
					"modal-brand modal-bottom reload-bank-modal withdraw-accounts-modal"
				}
				isOpen={promoShow}
				toggle={() => {
					setPromoShow(false);
				}}
				centered
				>
				<ModalBody>
					<form>
					<div className="top-title-right mb-3">
						<div className="content-header home-header shared-header modern-header">
						<div className="top-header">
							<div>
							<div>
								<button
								type="button"
								className="btn btn-icon"
								onClick={() => {
									setPromoShow(false);
								}}
								>
								<i className="fa fa-angle-left"></i>
								</button>
							</div>
							<div
								className="text-lblue1 text-underline"
								onClick={() => {
								setPromoShow(false);
								}}
								style={{ cursor: "pointer" }}
							>
								{t("CANCEL")}
							</div>
							</div>
						</div>
						</div>
					</div>
					<h5 className="mb-3">{t("PROMO_CODE")}</h5>
					<div className="mb-1 mt-3">
						<span className="font12 text-lgray2">
						{t("SELECT_PROMO_CODE")}
						</span>
					</div>
					<div
						className="all-box-with-icon mt-2"
						onClick={() => {
						setPromoId("");
						setPromoShow(false);
						}}
						style={{ cursor: "pointer" }}
					>
						<div className="first">
						<div>
							<VIcon
							icon={themeIcons.dashboardCommission}
							className="theme-icon-blue-size-20"
							/>
						</div>
						<div className="font14 f-weight-500 text-lblue">
							No bonus
						</div>
						</div>
					</div>
					{promoCodeList
						.filter((x) => x.productId == masterProductId)
						.map((promo, index) => {
						return (
							<div
							key={index}
							className="all-box-with-icon mt-2"
							onClick={() => {
								setPromoId(promo.id);
								setPromoShow(false);
							}}
							style={{ cursor: "pointer" }}
							>
							<div className="first">
								<div>
								<VIcon
									icon={themeIcons.dashboardCommission}
									className="theme-icon-blue-size-20"
								/>
								</div>
								<div className="font14 f-weight-500 text-lblue">
								{selectedGameName} - {promo.name}
								</div>
							</div>
							</div>
						);
						})}
					</form>
				</ModalBody>
				</Modal>
			</div>
			</ModalBody>
		</Modal>
	</>
    )
};

export default RecentBigWinInfo;
